import GeomRect from '../class-utility/GeomRect';

export default class extends TinyEmitter{
    constructor() {
        super();
        
        this.$el = $(window);
        this.rect = new GeomRect({
            x: 0,
            y: 0,
            w: this.$el.width(),
            h: this.$el.height()
        });

        this._setEvent();
    }
    _setEvent(){
        this.$el
            .on('load', ()=>{
                this.emit('LOAD');
            })
            .on('resize', ()=>{
                this.emit('RESIZE');
            })
            .on('scroll', ()=>{
                this.emit('SCROLL');
            })
            .on('resize scroll', ()=>{
                this._updateRect();
            });
    }
    _updateRect(){
        this.rect.y = this.$el.scrollTop();
        this.rect.w = this.$el.width();
        this.rect.h = this.$el.height();
    }
}
