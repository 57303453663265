/*
    [Loader Images]:画像リストを順に読み込みます

    @param:
    @return {void}
*/
export default class extends TinyEmitter{
    constructor($el) {
        super();

        this.$el = $el;
		this._now = 0;
		this._prev = 0;
		this._distance = 0;
		this._isUp = false;
		this._isDown = false;
		this._isChanged = false;

        this._update();
        this._setEvent();
    }
    _setEvent(){
        this.$el
            .on('scroll', ()=>{
                this._update();
            });
    }
    _update(){
        this._prev = this._now;
        this._now = this.$el.scrollTop();

		this._distance = this._now - this._prev;
		this._isUp = this._distance < 0;
		this._isDown = !this._isUp;
		this._isChanged = this._distance !== 0;
    }
}
