import PromiseWaterFall from './PromiseWaterFall';

/*
    [Loader Images]:画像リストを順に読み込みます

    @param:
    @return {void}
*/
export default class extends TinyEmitter{
    constructor(imageLists) {
        super();

        this.imageLists = imageLists;
        this.promiseWaterFall = new PromiseWaterFall(imageLists, true);
    }
    start(){
        this.promiseWaterFall
            .on('complete', ()=>{
                this.emit('complete');
            });

        this.promiseWaterFall.start();

        _.each(this.imageLists, (img, index)=>{
            let _img = new Image() ;
            _img.src = img;
            _img.onload = () => {
                this.emit('progress', {
                    completeSrc: _img,
                    total: this.imageLists.length,
                    percentTotal: Math.floor( (index + 1) / this.imageLists.length * 100 )
                });

                this.promiseWaterFall.find(img).end();
            };
        });
    }
}
