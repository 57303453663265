//ELEMENT
import Window from './_app-modules/class-element/Window';

//UI
import Modal from './_app-modules/class-ui/Modal';
import popupWindow from './_app-modules/fn-ui/popupWindow';

// //UTILITY
import Env from './_app-modules/class-utility/Env';
import LoaderImages from './_app-modules/class-utility/LoaderImages';
import PromiseSingle from './_app-modules/class-utility/PromiseSingle';
import PromiseWaterFall from './_app-modules/class-utility/PromiseWaterFall';
import Timeline from './_app-modules/class-utility/Timeline';
import GeomRect from './_app-modules/class-utility/GeomRect';
import Scroller from './_app-modules/class-utility/Scroller';

class App extends TinyEmitter{
    constructor() {
        super();

        this.devMode = true;
        this.page = null;
        this.breakPoint = 768;

        //ELEMENT
        this.window = new Window();

        //UI
        this.Modal = Modal;
        this.popupWindow = popupWindow;

        //UTILITY
        this.env = new Env();
        this.LoaderImages = LoaderImages;
        this.PromiseSingle = PromiseSingle;
        this.PromiseWaterFall = PromiseWaterFall;
        this.Timeline = Timeline;
        this.GeomRect = GeomRect;
        this.Scroller = Scroller;
    }
    log(_message){
        if (this.devMode) {
            console.log(_message);
        }
    }
    start(){
        //ELEMENT
        this.emit('START_BEFORE');

        $(()=>{
            this._startpage();
            this.emit('START_AFTER');
        });
    }
    _startpage(){
        if (this.page == null) {
            console.error('App have not Page Class...');

            return;
        }

        this.page = new this.page();
        this.page.init();
    }
}

window.app = new App();
