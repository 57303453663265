import PromiseWaterFall from './PromiseWaterFall';

/*
    TIMELINE:
    PromiseWaterFall(+PromiseSingle)を使用して、
    まとまった直列処理を行うPromiseWaterFallの集合体

    @param:
    @return {void}
*/
export default class extends TinyEmitter{
    constructor(stepCount, devMode) {
        super();

        this.tasks = [];

        for (var i = 0; i < stepCount; i++) {
            this.tasks.push({
                name: `step${i+1}`,
                items: []
            });
        }

        this.devMode = devMode || false;
        this._count = 0;
    }
    start(){
        //SETUP
        _.each(this.tasks, (_task, _index)=>{
            _task['promiseAll'] = new PromiseWaterFall(_task.items, this.devMode);
        });

        if (this.devMode) {
            console.info(`================ [timeline] start ================`);
        }

        this._run();
    }
    _run(){
        const _currentTask = this.getTaskByIndex(this._count);

        this.emit(_currentTask.name+'.enter');

        if (this.devMode) {
            console.info(`[timeline task]${_currentTask.name} on enter`);
        }

        _currentTask['promiseAll']
            .on('complete', ()=>{
                if (this.devMode) {
                    console.info(`[timeline task]${_currentTask.name} on end`);
                }

                 this._count += 1;

                 const _nextTask = this.getTaskByIndex(this._count);

                 if (_nextTask == undefined) {
                     this.emit(`complete`);
                     console.log('================ [timeline] all complete ================');
                 }else{
                     //次のタスクがあれば、再びrunを実行します
                     this._run();
                 }
            });

        _currentTask['promiseAll'].start();
    }
    addItemTo(stepIndex, fn){
        let _items = this.tasks[stepIndex-1].items;
        let _itemsCount = _items.length;

        _items.push(`step${stepIndex}.${_itemsCount+1}`);

        let resolve = ()=>{
            this.end(`step${stepIndex}.${_itemsCount+1}`);
        };

        this
            .on(`step${stepIndex}.enter`, ()=>{
                fn(resolve);
            });

        return this;//チェーンメソッド
    }
    getTaskByIndex(_index){
        return this.tasks[_index];
    }
    getTaskByName(_name){
        return _.findWhere(this.tasks, {name: _name});
    }
    end(_name){
        const _taskName = _name.split('.')[0];
        const _taskItemName = _name.split('.')[1];

        if (_taskItemName == undefined) {
            console.error('taskItem名が指定されていないため、Timeline.end()を中断します。');
            return;
        }

        const _targetTask = this.getTaskByName(_taskName);

        _targetTask['promiseAll'].find(_name).end();
    }
}
