export default class {
    constructor(param) {
        this.id = '';
        this.$triggerBtn = $('.js-modal');
        this.enableBgClickClose = true;
        this.position = ['center','center'];
        this.OPEN_SPEED = .5;
        this.CLOSE_SPEED = .3;
        this.onSetupAfter = ()=>{};
        this.onOpenBefore = ()=>{};
        this.onOpenAfter = ()=>{};
        this.onCloseBefore = ()=>{};
        this.onCloseAfter = ()=>{};
        Object.assign(this, param);
        this.event = _.extend({}, Backbone.Events);
        this.$el = $('#' + this.id);
        this.triggerClass = '.js-' + this.id + '-trigger';
        this.$clickedBtn = {};
        this.isOpen = false;
        this.CLASS_CONTS_INNER = 'modal_conts_inner';
        this.CLASS_CONTS = 'modal_conts';
        this.CLASS_INNER = 'modal_inner';
        this.CLASS_OVERLAY = 'modal_overlay';
        this.CLASS_LOADER = 'modal_loader';
        this.CLASS_CLOSE = 'modal_close';
    }

    start(){
        if (!this.$el.length) { return };

        this._setup();
        this.setEvents();
    }
    _setup(){
        //ADD CLASS ENV
        // if (SHD.env.isMobile) {
        //     this.$el.addClass('-mobile');
        // }

        /*
            各modal要素でwrapする&キャッシュ
         */
        this.$el.wrapInner('<div class="' + this.CLASS_CONTS_INNER + '" />');
        this.$contsInner = this.$el.find('.' + this.CLASS_CONTS_INNER);

        this.$el.wrapInner('<div class="' + this.CLASS_CONTS + '" />');
        this.$conts = this.$el.find('.' + this.CLASS_CONTS);

        this.$el.wrapInner('<div class="' + this.CLASS_INNER + '" />');
        this.$inner = this.$el.find('.' + this.CLASS_INNER);

        this.$el.wrapInner('<div class="' + this.CLASS_OVERLAY + '" />');
        this.$overlay = this.$el.find('.' + this.CLASS_OVERLAY);

        this.$overlay.prepend('<div class="' + this.CLASS_LOADER + '" />');
        this.$loader = this.$el.find('.' + this.CLASS_LOADER);

        this.$contsInner.prepend('<a href="" class="' + this.CLASS_CLOSE + '" />');
        this.$close = this.$el.find('.' + this.CLASS_CLOSE);


        //ADD CLASS POSITION
        for (var i = 0; i < this.position.length; i++) {
            if (i == 0) {
                this.$el.addClass('-posH-' + this.position[0]);
            }else{
                this.$el.addClass('-posV-' + this.position[1]);
            }
        }

        this.onSetupAfter(this);
        this.event.trigger('SETUP_AFTER');
    }
    setEvents(){
        $(document)
            .on('click', this.triggerClass, (e) => {
                this.$clickedBtn = $(e.currentTarget);
                this.open();

                return false;
            });

        this.$close
            .on('click', ()=>{
                this.close();

                return false;
            });

        if (this.enableBgClickClose) {
            this.$conts
                .on('click', (e) => {
                    this.close();
                    return false;
                });

            this.$contsInner
                .on('click', (e) => {
                    e.stopPropagation();
                });
        }

        $(window)
            .on('resize', () => {
                this.update();
            });
    }

    update(){
        //CHECH IS OVER HEIGHT
        //コンテンツがwindowよりはみ出た場合は制御用のclassを付与
        if ($(window).height() < this.$contsInner.outerHeight()) {
            this.$el.addClass('-overHeight');
        }else{
            this.$el.removeClass('-overHeight');
        }

        //ALIGN CENTER
        //コンテンツを中央に
        this.$contsInner
            .css({
                marginTop: -(this.$contsInner.outerHeight() / 2),
                marginLeft: -(this.$contsInner.outerWidth()　/　2)
            });
    }
    open(){
        $('body').addClass('-modal-lock');

        this.onOpenBefore(this);
        this.event.trigger('OPEN_BEFORE');

        this.$el.css('display', 'block');

        TweenMax.to(this.$el, this.OPEN_SPEED, {
            opacity:　1,
            onComplete: () => {
                this.isOpen = true;
                this.onOpenAfter(this);
                this.event.trigger('OPEN_AFTER');
                this.$close.addClass('-show');
            }
        });

        this.update();
    }
    close(){
        this.isOpen = false;
        this.$clickedBtn = null;

        $('body').removeClass('-modal-lock');
        this.$close.removeClass('-show');

        this.onCloseBefore(this);
        this.event.trigger('CLOSE_BEFORE');

        TweenMax.to(this.$el, this.CLOSE_SPEED, {
            opacity:　0,
            display:　'none',
            onComplete: () => {
                this.isOpen = false;
                this.onCloseAfter(this);
                this.event.trigger('CLOSE_AFTER');
            }
        });
    }
    cleanup(){
        this.$contsInner.find('*').not('.' + this.CLASS_CLOSE).remove();
    }
    destory(){
        var self = this;
    }
}
