import PromiseSingle from './PromiseSingle';

/*
    [PROMISE WATERFALL]
*/
export default class extends TinyEmitter{
    constructor(names, devMode) {
        super();

        this.names = names;
        this.devMode = devMode || false;
        this.isRuning = false;
        this.isEnded = false;

        this.list_promiseSingle = [];

        //namesから各PromiseSingleインスタンスを生成
        _.each(this.names, (name)=>{
            this.list_promiseSingle
                .push(new PromiseSingle(name, this.devMode));
        });
    }
    start(){
        this.isRuning = true;

        const _listPromise = _.pluck(this.list_promiseSingle, 'promise');

        Promise
            .all(_listPromise)
            .then(()=>{
                this.isEnded = true;
                this.isRuning = false;
                this.emit('complete');
            });
    }
    find(name){
        //見つかったPromiseSingleインスタンスを返却します
        return _.findWhere(this.list_promiseSingle, { name: name });
    }
}
