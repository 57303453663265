export default class{
    constructor(param) {
        this.x = 0;
        this.y = 0;
        this.w = 0;
        this.h = 0;
        this.color = '#f00';
        Object.assign(this, param);
    }
    getleft(){
        return this.x;
    }
    getTop(){
        return this.y;
    }
    getRight(){
        return this.x + this.w;
    }
    getBottom(){
        return this.y + this.h;
    }
    getAreaSize(){
        return this.w * this.h;
    }
    //交差しているかどうか
    intersect(rect){
        return this.contains(rect.x, rect.y)
    		|| this.contains(rect.x, rect.getBottom())
    		|| this.contains(rect.getRight(), rect.y)
            || this.contains(rect.getRight(), rect.getBottom());
    }
    //対象のポイントを含んでいるかどうか
    contains(x,y){
        var l, r, t, b;

		if (this.x < this.getRight()) {
			l = this.x;
			r = this.getRight();
		} else {
			l = this.getRight();
			r = this.x;
		}

		if (this.y < this.getBottom()) {
			t = this.y;
			b = this.getBottom();
		} else {
			b = this.y;
			t = this.getBottom();
		}

		return (x >= l) && (x <= r)&& (y >= t) && (y <= b);
    }
    //対象のrectを含んでいるかどうか
	containsRect(rect) {
        //まず対象が自分よりも面積が大きい場合はreturn
        if (this.getAreaSize() < rect.getAreaSize() ) {
            return false;
        }

		return this.contains(rect.x, rect.y)
                && this.contains(rect.x, rect.getBottom())
				&& this.contains(rect.getRight(), rect.y)
				&& this.contains(rect.getRight(), rect.getBottom());
    }
    //完全に等しいか
    equal(rect){
        return this.x === rect.x
    		&& this.y === rect.y
    		&& this.w === rect.w
            && this.h === rect.h;
    }
    isEmpty () {
    	return this.w === 0 && this.h === 0;
    }
}
