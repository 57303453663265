/*
    [popupWindow]:ポップアップウインドウを表示させます

    @param: param {object}
        -url {string}
        -name {number} ウインドウ名
        -width {number}
        -height {number}
        -menubar {string} yes|no
        -toolbar {string} yes|no
        -scrollbars {string} yes|no
        -isCenter {boolean}

    @return {void}
*/

export default function(param){
    let _param = {
        url: 'example.com',
        name: 'no name',
        width: 300,
        height: 600,
        menubar: 'no',
        toolbar: 'no',
        scrollbars: 'yes',
        isCenter: true
    };

    Object.assign(_param, param);

    let setting = `width=${_param.width}, height=${_param.height}, menubar=${_param.menubar}, toolbar=${_param.toolbar}, scrollbars=${_param.scrollbars}`;

    (()=>{
        if (!_param.isCenter) { return };

        setting += `, left=${(screen.width / 2) - (_param.width / 2)}, top=${(screen.height / 2) - (_param.height / 2)}`;
    })();

    window.open(_param.url, _param.name, setting);
}
