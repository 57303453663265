/*
    [PROMISE SINGLE]
*/
export default class extends TinyEmitter{
    constructor(name, devMode) {
        super();

        this.name = name;
        this.devMode = devMode || false;
        this.promise = new Promise((resolve, reject) => {
            this.on('complete', ()=>{
                if (this.devMode) {
                    console.info(`::::::::::::[single task]${this.name} on end`);
                }

                resolve();
            });
        });
    }
    end(){
        this.emit('complete');
    }
}
